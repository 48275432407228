// Alerts
alert-variant($background, $border, $text-color)
  background-color $background
  color $text-color

  hr
    border-top-color darken($border, 5)

  .alert-link
    color darken($text-color, 10)
