.logo-branding
  text-align: center
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 20px;
  background-image: url('img/transparent.png')

  h5
    text-transform: uppercase
    letter-spacing: 1px;
    color: #999;
    margin-top: 0;

  img
    max-width: 100%;
    display: block
    max-height: 140px;
    margin: auto;
    margin-bottom: 20px;


  &.logo-dark
    background-image: url('img/transparent-dark.png')

  a
    font-weight: $font-weight-bold
    border: 1px solid $color-blue-light;
    padding: 4px 10px;
    text-transform: uppercase
    border-radius: 3px;
    font-size: $font-size-small;
    letter-spacing: 1px;
    color: $color-blue-light;
    &:hover
      color: white
      background: $color-blue-light;
