/*==========  Big Tabs  ==========*/
.big-tabs
  text-transform: uppercase
  font-size: $font-size-small;
  letter-spacing: 1px;
  text-align: center;
  position: relative;
  margin: 0;
  margin-bottom: 40px;
  li
    display: inline-block
    float: none;
    font-weight: $font-weight-bold
    margin: 0;
    border: 1px solid darken($color-blue-light, 20%);
    margin-left: -1px;

    &:first-child
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;

    &:last-child
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

    a
      color: darken($color-blue-light, 20%);
      line-height: 32px;
      display: inline-block
      padding: 0 30px;

    &.active
      a
        background: darken($color-blue-light, 20%);
        color: white;
