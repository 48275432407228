inner-padding = 30px

.switchboard
  margin 20px 0
  display: flex;
  flex-flow: row wrap;

.switchboard-item
  flex: 1;
  border-right: 1px $color-gray solid;
  border-bottom: 1px $color-gray solid;
  position: relative;
  min-height: 100px;
  background: white
  cursor: pointer;
  will-change: transform;

  &.disabled
    background: lighten($color-gray, 80%);

    .test
      visibility: hidden;

  &:focus
    outline: none;

  &:hover
    +breakpoint("desktop")
      transform: scale(1.05) translateZ(0);
      backface-visibility: hidden;
      filter: blur(0px);
      box-shadow: 0 0 10px rgba(0,0,0,.2);
      transition: transform 0.2s ease;
      z-index: 1000;

  .name
    margin-top: 0;
    margin-bottom: 10px;
    font-size: $font-size-base;
    font-weight: 500;

  .desc
    font-size: $font-size-small;

  .content
    padding: inner-padding;
    padding-right: 100px;

  .switch
    position: absolute;
    top: inner-padding;
    right: inner-padding;

  &[data-switch-position="left"]
    .content
      padding-right: inner-padding;
      padding-left: 100px;

    .switch
      right: auto;
      top: inner-padding;
      left: inner-padding;

.switchboard-responsive
  .switchboard-item
    flex-basis: 100%;
    max-width: 100%;

    +breakpoint("tablet", "max")
      &:nth-child(-n+1)
        border-top: 1px $color-gray solid;
      &:nth-child(1n+1)
        border-left: 1px $color-gray solid;

    +breakpoint("tablet")
      +breakpoint("desktop", "max")
        flex-basis: (100% / 2);
        max-width: (100% / 2);

        &:nth-child(-n+2)
          border-top: 1px $color-gray solid;
        &:nth-child(2n+1)
          border-left: 1px $color-gray solid;

for num in 2 3 4 5
  num_quoted = '"' + num + '"'

  :not(.switchboard-responsive)[data-columns={num_quoted}]
    .switchboard-item
      flex-basis: (100% / num);
      max-width: (100% / num);

      &:nth-child(-n+{num})
        border-top: 1px $color-gray solid;
      &:nth-child({num}n+1)
        border-left: 1px $color-gray solid;

  .switchboard-responsive[data-columns={num_quoted}]
    +breakpoint("desktop")
      .switchboard-item
        flex-basis: (100% / num);
        max-width: (100% / num);

        &:nth-child(-n+{num})
          border-top: 1px $color-gray solid;
        &:nth-child({num}n+1)
          border-left: 1px $color-gray solid;
