//== Jumbotron

$jumbotron-padding ?=            30px
$jumbotron-color ?=              inherit
$jumbotron-bg ?=                 $gray-lighter
$jumbotron-heading-color ?=      inherit
$jumbotron-font-size ?=          ceil($font-size-base * 1.5)
$jumbotron-heading-font-size ?=  ceil($font-size-base * 4.5)

.jumbotron
  padding: $padding-base-vertical*2 $padding-base-horizontal*2
  margin-bottom: padding-vertical-small
  color: $color-text-contrast
  background-color: $bg-color-dark
  text-align: center

  &.has-header
    +breakpoint("desktop")
      padding-top: 150px;

  +breakpoint("desktop")
    margin-bottom: $padding-large-vertical

  h1,
  .h1
    color: $color-text-contrast
    margin: 1.5rem 0

  h2,
  .h2
    background-color: $color-text-contrast
    border-radius: 50%
    display: block
    margin: 0 auto
    overflow: hidden
    width: 100px
    height: 100px
    position: relative

    img
      position: absolute
      display: block
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

  > p
    color: $color-text-contrast-light
    margin 0 auto
    margin-bottom: (padding-vertical / 1.5)
    font-weight: $font-weight-normal
    max-width 600px
    font-size 16px
    padding: 0 20px

    &:last-child
      margin-bottom: 0;

  +breakpoint("tablet")
    padding: ceil($padding-large-vertical * 4) $padding-large-horizontal

    h1,
    .h1
      font-size: ($font-size-base * 2.5)
