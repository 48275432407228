.scope-toggle
  position relative
  display inline-block
  margin-right 10px
  color $color-text-light
  transition background-color 0.2s ease-in-out
  
  .scope-input
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    z-index 100
    opacity 0
    outline none
    margin 0
    
    &:checked + .status
      background-color #F1F1F1
      &:before
        content ''
        top 2px
        border none
        width 11px
        font-size $font-size-small
        
    &:disabled + .status
      opacity 0.3
      background-color #F1F1F1
      
  .status
    margin 0
    padding 10px 15px
    border 1px solid #DDDDDD
    border-radius 3px
    transition background-color 0.2s ease-in-out
    &:before
      content ''
      display inline-block
      font-family "budicon-font" !important
      font-style normal !important
      font-weight normal !important
      font-variant normal !important
      text-transform none !important
      speak none
      line-height 1
      -webkit-font-smoothing antialiased
      margin-right 5px
      position relative
      top 2px
      font-size $font-size-small
      border 1px solid #aaa
      width 11px
      height 11px
      border-radius 3px