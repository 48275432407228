/*==========  Footer  ==========*/

footer.site-footer
  clearfix();
  font-size: $font-size-small;
  padding-top: 30px;
  background-color: white;

  +breakpoint("desktop")
    padding-top: 40px;

  a
    color: darken($color-gray, 60%);

    &:active
      text-decoration: underline

    &:hover
      color: darken($color-gray, 40%);

  .logo
    margin-bottom: 15px;
    text-align: center;

    img
      width: 20px;

    +breakpoint("desktop")
      float: left;
      width: 8%;
      text-align: left;
      padding-top: 10px;

      img
        width: 30px;

  h6
    color: darken($color-gray, 20%);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 0;
    padding: 10px 0;

  .footer-grid
    grid(1, 0, ".column")
    text-align: left;

    +breakpoint("desktop")
      grid(7, 10px, ".column")
      float: left;
      width: 92%;
      margin-bottom: 25px;

  .column
    margin-bottom: 10px;

    +breakpoint("desktop")
      margin-bottom: 15px;

    .item
      margin-bottom: 4px;
      a
        border-bottom: 1px solid lighten($color-gray, 60%);
        display: block;
        padding: 10px 0;

        +breakpoint("desktop")
          padding: 0;
          border: 0;
          line-height: 1.8;

  .contact
    clearfix();
    padding-top: 10px;
    float: left;
    width: 100%;
    position: relative;

    +breakpoint("desktop")
      border-bottom: 1px solid lighten($color-gray, 60%);
      border: 0;
      float: none;
      width: auto;
      padding-top: 0;
      position: static;

    .column
      +breakpoint('desktop', 'max')
        position relative
        width 50%
        
      .item
        a
          border: 0;

        &.item-phone-label
          font-size: $font-size-small;
          text-transform: uppercase;
          letter-spacing: 1px;
          opacity: .5;
          padding-bottom: 5px;
          margin-bottom: 0;

        &.item-text
          opacity: 0.7;

    .no-heading
      text-align: right;
      padding-top: 37px;
      position: absolute;
      right: 0;
      top: 18px;

      +breakpoint("desktop")
        position: static;

      .item
        font-size: $font-size-small;

        a
          padding: 0;
          padding-bottom: 5px;

          +breakpoint("desktop")
            padding: 0;

  .social
    clearfix();
    padding-top: 10px;
    text-align: left;
    min-height: 60px;
    overflow: hidden;

    > div
      height: 20px;
      margin-right: 6px;
      margin-bottom: 6px;

      +breakpoint("desktop")
        float: left;

    .twitter
      width 197px
        
    .facebook
      width 135px
    
    .linkendin
      width: 120px

  .colophon
    clear: both;

    +breakpoint("mobile-landscape")
      grid(2, 0, ".column")

    +breakpoint("desktop")
      border-top: 1px solid lighten($color-gray, 60%);

    ul
      +breakpoint("mobile-landscape")
        margin-top: 15px;

      +breakpoint("desktop")
        margin-top: 5px;

    li
      margin:  5px 0;
      padding: 0 5px;

      +breakpoint("desktop")
        margin-left: 10px;

  [class^='skype_pnh_container']
    display: none !important;
  [class^='skype_pnh_print_container']
    display: inline !important;

.theme-dark
  .site-footer
    background-color $bg-color-dark
    border-top 0

    h6
      color $color-text-contrast
    .item
      color $color-text-contrast
      a
        color $color-text-contrast
        border-color $color-line-contrast
    .colophon
      border-color $color-line-contrast
      color $color-text-contrast
