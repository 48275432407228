//== Pagination
//
//##

$pagination-color ?=                   $link-color
$pagination-bg ?=                      #fff
$pagination-border ?=                  #ddd

$pagination-hover-color ?=             $link-hover-color
$pagination-hover-bg ?=                $gray-lighter
$pagination-hover-border ?=            #ddd

$pagination-active-color ?=            #fff
$pagination-active-bg ?=               $brand-primary
$pagination-active-border ?=           $brand-primary

$pagination-disabled-color ?=          $gray-light
$pagination-disabled-bg ?=             #fff
$pagination-disabled-border ?=         #ddd

// Pagination
pagination-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius)
  > li
    > a,
    > span
      padding $padding-vertical $padding-horizontal
      font-size $font-size
      line-height $line-height

    &:first-child
      > a,
      > span
        border-left-radius($border-radius)

    &:last-child
      > a,
      > span
        border-right-radius($border-radius)
