@charset "UTF-8";

// Core variables and mixins
@import 'lib/vars/index';
@import 'lib/mixins/index';

// Vendor
@import 'vendor/animate.css/animate.css';
@import 'vendor/normalize.css/normalize.css';
@import 'lib/budicon/budicon.fixed.css';

// Core CSS
@import "lib/scaffolding/index"
@import "lib/code/index"
@import "lib/grid/index"
@import "lib/tables/index"
@import "lib/forms/index"
@import "lib/buttons/index"
@import 'lib/typography/index';
@import 'lib/themes/index';

// Components
@import 'lib/alerts/index';
@import 'lib/buttons/index';
@import 'lib/cards/index';
@import 'lib/breadcrumb/index';
@import 'lib/codemirror/index';
@import 'lib/spinner/index';
@import 'lib/code-picker/index';
@import 'lib/navs/index';
@import 'lib/showcase-tabs/index';
@import "lib/component-animations/index"
@import "lib/dropdowns/index"
@import "lib/button-groups/index"
@import "lib/input-groups/index"
@import "lib/navbar/index"
@import 'lib/notifications/index';
@import "lib/pagination/index"
@import "lib/pager/index"
@import "lib/labels/index"
@import "lib/badges/index"
@import "lib/jumbotron/index"
@import "lib/thumbnails/index"
@import "lib/progress-bars/index"
@import "lib/media/index"
@import "lib/list-group/index"
@import "lib/panels/index"
@import "lib/wells/index"
@import "lib/close/index"
@import "lib/scope/index"

// Components w/ JavaScript
@import "lib/modals/index"
@import "lib/tooltip/index"
@import "lib/popovers/index"
@import "lib/carousel/index"

// Utility classes
@import "lib/utilities/index"
@import "lib/responsive/index"

// Helpers
@import "lib/helpers/index"

// Auth0 Custom Components
@import 'lib/header/index';
@import 'lib/sidebar-box/index';
@import 'lib/banner/index';
@import 'lib/big-tabs/index';
@import 'lib/ui-switch/index';
@import 'lib/switchboard/index';
@import 'lib/phone-mockup/index';
@import 'lib/pills/index';
@import 'lib/browser/index';
@import 'lib/center-title-block/index';
@import 'lib/footer/index';
@import 'lib/font/index';
@import 'lib/sidebar-box/index';
@import 'lib/sub-header/index';
@import 'lib/prettify/index';
@import 'lib/quotes/index';
@import 'lib/try-banner/index';
@import 'lib/circle-logo/index';
@import 'lib/extension/index';
@import 'lib/colors/index';
@import 'lib/budicon/budicon';
@import 'lib/logos/index';
@import 'lib/highlight/index';
