/*==========  Center title block  ==========*/

.center-title-block
  text-align: center
  max-width: 680px;
  padding: 0;
  margin: auto;
  margin-bottom: 80px;
  max-width: 600px;
  margin-bottom: 40px

  .icon-badge
    margin-bottom: 10px;

  h2
    margin: 10px 0;
  p
    color: #798291;
    // line-height: 2;
    font-size: 1.2rem;
    margin-top: 0;
    color: rgba(0,0,0,.5)

  img
    margin-top: 17px
    height: 26px;

    &.help-circle
      margin-top: -5px



span.icon-badge
  background: $color-gray;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  display: inline-block
  color: $color-red;
  font-size: 30px;
  line-height: 72px;
