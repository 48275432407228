reset-text()
  font-family $font-family-base
  // We deliberately do NOT reset font-size.
  font-style normal
  font-weight normal
  letter-spacing normal
  line-break auto
  line-height $line-height-base
  text-align left // Fallback for where `start` is not supported
  text-align start
  text-decoration none
  text-shadow none
  text-transform none
  white-space normal
  word-break normal
  word-spacing normal
  word-wrap normal
