.phone-mockup
  display block
  position relative
  margin 40px auto
  width 300px
  border 1px solid #999
  border-radius 1.2em
  background-color white

  &:before
    content ''
    position absolute
    width 70px
    height 8px
    border 1px solid #999
    border-radius 0.8em
    top 16px
    left 50%
    transform translateX(-50%);

  > img
    max-width calc(100% - 20px) !important
    margin 45px 10px 45px 10px !important
    border 1px solid #999 !important
