/*==========  Sidebar Box  ==========*/
.sidebar-box
  max-width: 230px;
  border: 1px solid #eee;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 14px 20px;
  list-style: none;
  font-size: $font-size-base;

  strong
    text-transform: uppercase
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    margin-bottom: 10px;
    display: block
    letter-spacing: 1px;
    color: #999;

  ul
    padding: 0;
    margin: 0;

    list-style: none;

    a
      color: #666;

      &:hover
        color: black;

    li
      line-height: 32px;
      margin: 0;

      li 
        margin: 0;

      i:before
        content: "\e16f";
        font-family: "budicon-font" !important;
        speak: none;
      i
        width: 10px;
        height: 10px;
        display: inline-block
        font-family: "budicon-font" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        margin-right: 8px;
        position: relative;
        top: 2px;
        height: 14px;
        // background: red;

      &.selected
        a
          color: black !important;
          // font-weight: $font-weight-bold;
