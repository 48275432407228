/*==========  Subheader  ==========*/
.sub-header
  border-radius: 3px;
  margin-bottom: 40px;
  background-color: $color-blue;
  background: linear-gradient(120deg, darken($color-red, 40%) 0%, $color-blue 100%); /* W3C */
  font-weight: $font-weight-bold
  font-size: $font-size-small;
  color: white;
  a
    color: white;

  span.sub-header-text, .external-links a
    padding: 14px 20px;
    display: inline-block

  .btn
    line-height: 32px
    font-size: $font-size-small;
    background: none;
    border: 0;
    border-radius: 0;
    border-right: 1px solid #ccc;
    text-transform: none
    background: linear-gradient(180deg, transparent 30%, rgba(0,0,0,.8) 100%); /* W3C */
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    letter-spacing: .5px;

    position: relative;
    text-align: left;
    padding-left: 20px;
    padding-right: 40px;
    letter-spacing: 0.5px;
    display: inline-block

    span
      position: absolute;
      right: 20px;
      top: 20px;

    &:hover
      background: linear-gradient(180deg, transparent 0%, rgba(0,0,0,.8) 100%); /* W3C */
      color: white;


    &.dropdown-toggle
      color: white;

    &:focus
      outline: none;

  .dropdown
    display: inline-block
  .dropdown-menu

    li
      margin: 0;

    .divider
      margin: 10px 0;
      font-size: $font-size-base;

  ul.external-links
    display: inline-block
    float: right;
    li
      float: left
      display: inline-block
      color: white;
      font-size: $font-size-small;
      padding: 0;
      margin: 0;
      a
        opacity: .6;
        &:hover
          opacity: 1;


  &.sub-header-dropdown
    background: #5D676F;
