.cm-s-auth0 {
  background-color: #263238;
  color: rgba(233, 237, 237, 1);
  font-family: "Roboto mono", Menlo, Monaco, monospace;
  line-height 1.6;
  border-radius 3px
}
.cm-s-auth0 .CodeMirror-gutters {
  background: #202A2F;
  color: rgb(83,127,126);
  border: none;
}

.cm-s-auth0 .CodeMirror-linenumber {
  left: auto;
  background-color: #202A2F;
  padding-right: 10px;
}
.cm-s-auth0.CodeMirror-scroll{
  border-radius 3px
}
.cm-s-auth0.CodeMirror {
  background-color: darken(#263238, 5%);
  transition background-color 0.3s ease-in-out
}

.cm-s-auth0.CodeMirror-focused {
  background-color: #263238;
}
.cm-s-auth0 .CodeMirror-guttermarker, .cm-s-auth0 .CodeMirror-guttermarker-subtle, .cm-s-auth0 .CodeMirror-linenumber { color: rgba(255,255,255,.5); }
.cm-s-auth0 div.CodeMirror-cursor { border-left: 1px solid #f8f8f0; }
.cm-s-auth0 div.CodeMirror-selected { background: rgba(255, 255, 255, 0.15); }
.cm-s-auth0.CodeMirror-focused div.CodeMirror-selected { background: rgba(255, 255, 255, 0.10); }
.cm-s-auth0 .CodeMirror-line::selection, .cm-s-auth0 .CodeMirror-line > span::selection, .cm-s-auth0 .CodeMirror-line > span > span::selection { background: rgba(255, 255, 255, 0.10); }
.cm-s-auth0 .CodeMirror-line::-moz-selection, .cm-s-auth0 .CodeMirror-line > span::-moz-selection, .cm-s-auth0 .CodeMirror-line > span > span::-moz-selection { background: rgba(255, 255, 255, 0.10); }

.cm-s-auth0 .CodeMirror-hscrollbar::-webkit-scrollbar-track {
  background-color: #263238;
}

.cm-s-auth0 .CodeMirror-scroll::-webkit-scrollbar-track {
  background-color: #263238;
}

.cm-s-auth0 .CodeMirror-activeline-background { background: rgba(0, 0, 0, 0); }
.cm-s-auth0 .cm-keyword { color: #ff9a56; }
.cm-s-auth0 .cm-operator { color: rgba(233, 237, 237, 1); }
.cm-s-auth0 .cm-variable-2 { color: #40C2EF; }
.cm-s-auth0 .cm-variable-3 { color: rgba(233, 237, 237, 1); }
.cm-s-auth0 .cm-builtin { color: #DECB6B; }
.cm-s-auth0 .cm-atom { color: #40C2EF; }
.cm-s-auth0 .cm-number { color: #40C2EF; }
.cm-s-auth0 .cm-def { color: rgba(233, 237, 237, 1); }
.cm-s-auth0 .cm-string { color: #00fcd4; }
.cm-s-auth0 .cm-string-2 { color: #80CBC4; }
.cm-s-auth0 .cm-comment { color: #546E7A; }
.cm-s-auth0 .cm-variable { color: #FFA0E7; }
.cm-s-auth0 .cm-tag { color: #ff9a56; }
.cm-s-auth0 .cm-meta { color: #80CBC4; }
.cm-s-auth0 .cm-attribute { color: #FFA0E7; }
.cm-s-auth0 .cm-property { color: #40C2EF; }
.cm-s-auth0 .cm-qualifier { color: #FFA0E7; }
.cm-s-auth0 .cm-error {
  color: rgba(255, 255, 255, 1.0);
  background-color: #EC5F67;
}
.cm-s-auth0 .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
