.theme-dark
  color: $color-text-contrast

  h1, h2, h3, h4, h5
    color: $color-text-contrast

  blockquote
    color $color-text-contrast
    
    .author
      color $color-text-contrast
  p
    color $color-text-contrast

  p.caption
    color $color-text-contrast-lighter
