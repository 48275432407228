//
// Code (inline and block)
// --------------------------------------------------

$code-color ?=                #62626B
$code-bg ?=                   transparent

$kbd-color ?=                 #fff
$kbd-bg ?=                    #333

$pre-bg ?=                    #f5f5f5
$pre-color ?=                 $gray-dark
$pre-border-color ?=          #ccc
$pre-scrollable-max-height ?= 340px


// Inline and block code styles
code,
kbd,
pre,
samp
  font-family $font-family-monospace

// Inline code
code
  padding 0 6px 2px 6px;
  font-size 90%
  color $color-text
  background-color $bg-color-gray-light;
  border-radius $border-radius-base
  border-radius: 3px;
  -webkit-font-smoothing: subpixel-antialiased;

// User input typically entered via keyboard
kbd
  padding 2px 4px
  font-size 90%
  color $kbd-color
  background-color $kbd-bg
  border-radius $border-radius-small
  box-shadow inset 0 -1px 0 rgba(0, 0, 0, .25)

  kbd
    padding 0
    font-size 100%
    font-weight bold
    box-shadow none

// Blocks of code
pre
  display block
  padding (($line-height-computed - 1) / 2)
  margin 0 0 ($line-height-computed / 2)
  font-size ($font-size-base - 1) // 14px to 13px
  line-height $line-height-base
  word-break break-all
  word-wrap break-word
  color $pre-color
  background-color $pre-bg
  border-radius $border-radius-base
  border: 0;

  // Account for some code outputs that place code tags in pre tags
  code
    padding 0
    font-size inherit
    color inherit
    white-space pre-wrap
    background-color transparent
    border-radius $border-radius-base

// Enable scrollable blocks of code
.pre-scrollable
  max-height $pre-scrollable-max-height
  overflow-y scroll
