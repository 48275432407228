//
// Tooltips
// --------------------------------------------------

//** Tooltip max width
$tooltip-max-width ?=         200px
//** Tooltip text color
$tooltip-color ?=             #fff
//** Tooltip background color
$tooltip-bg ?=                #000
$tooltip-opacity ?=           .9

//** Tooltip arrow width
$tooltip-arrow-width ?=       5px
//** Tooltip arrow color
$tooltip-arrow-color ?=       $tooltip-bg

// Base class
.tooltip
  position absolute
  z-index $zindex-tooltip
  display block
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  reset-text()
  font-family $font-family-base
  font-size $font-size-small

  opacity-ie(0)

  &.in
    opacity-ie($tooltip-opacity)

  &.top
    margin-top -3px
    padding $tooltip-arrow-width 0

  &.right
    margin-left 3px
    padding 0 $tooltip-arrow-width

  &.bottom
    margin-top 3px
    padding $tooltip-arrow-width 0

  &.left
    margin-left -3px
    padding 0 $tooltip-arrow-width

// Wrapper for the tooltip content
.tooltip-inner
  max-width $tooltip-max-width
  padding 3px 8px
  color $tooltip-color
  text-align center
  background-color $tooltip-bg
  border-radius $border-radius-base

// Arrows
.tooltip-arrow
  position absolute
  width 0
  height 0
  border-color transparent
  border-style solid

// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip
  &.top .tooltip-arrow
    bottom 0
    left 50%
    margin-left (-($tooltip-arrow-width))
    border-width $tooltip-arrow-width $tooltip-arrow-width 0
    border-top-color $tooltip-arrow-color

  &.top-left .tooltip-arrow
    bottom 0
    right $tooltip-arrow-width
    margin-bottom (-($tooltip-arrow-width))
    border-width $tooltip-arrow-width $tooltip-arrow-width 0
    border-top-color $tooltip-arrow-color

  &.top-right .tooltip-arrow
    bottom 0
    left $tooltip-arrow-width
    margin-bottom (-($tooltip-arrow-width))
    border-width $tooltip-arrow-width $tooltip-arrow-width 0
    border-top-color $tooltip-arrow-color

  &.right .tooltip-arrow
    top 50%
    left 0
    margin-top (-($tooltip-arrow-width))
    border-width $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0
    border-right-color $tooltip-arrow-color

  &.left .tooltip-arrow
    top 50%
    right 0
    margin-top (-($tooltip-arrow-width))
    border-width $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width
    border-left-color $tooltip-arrow-color

  &.bottom .tooltip-arrow
    top 0
    left 50%
    margin-left (-($tooltip-arrow-width))
    border-width 0 $tooltip-arrow-width $tooltip-arrow-width
    border-bottom-color $tooltip-arrow-color

  &.bottom-left .tooltip-arrow
    top 0
    right $tooltip-arrow-width
    margin-top (-($tooltip-arrow-width))
    border-width 0 $tooltip-arrow-width $tooltip-arrow-width
    border-bottom-color $tooltip-arrow-color

  &.bottom-right .tooltip-arrow
    top 0
    left $tooltip-arrow-width
    margin-top (-($tooltip-arrow-width))
    border-width 0 $tooltip-arrow-width $tooltip-arrow-width
    border-bottom-color $tooltip-arrow-color


/*==========  Tip  ==========*/
.tip
  a
    font-weight: $font-weight-bold
    color: white;
    border-color: white !important;
  .tip-inner
    max-width: 300px;


// @media screen and (max-width: 992px)
//   footer.site-footer
//     .col-md-11
//       float: none;
//     nav
//       float: none;
//       display: block
//       clear:both;
//       text-align: left;
//       margin: 0;
//       padding: 0;
//       margin-bottom: 14px;
//       ul
//         margin: 0;
//         border-bottom: 1px solid #f1f1f1;
//         padding: 0;
//       li
//         display: block
//         margin: 0;
//       a
//         display: block
//         border-top: 1px solid #f1f1f1;
//         margin: 0;
//         padding: 14px 0;
