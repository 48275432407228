//
// Grid system
// --------------------------------------------------

//** Number of columns in the grid.
$grid-columns ?=              12
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width ?=         30px
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint ?=     $screen-sm-min
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max ?= ($grid-float-breakpoint - 1)


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet ?=           (720px + $grid-gutter-width)
//** For `$screen-sm-min` and up.
$container-sm ?=               $container-tablet

// Medium screen / desktop
$container-desktop ?=          (940px + $grid-gutter-width)
//** For `$screen-md-min` and up.
$container-md ?=               $container-desktop

// Large screen / wide desktop
$container-large-desktop ?=    (1140px + $grid-gutter-width)
//** For `$screen-lg-min` and up.
$container-lg ?=               $container-large-desktop

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs ?=                480px
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min ?=            $screen-xs
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone ?=             $screen-xs-min

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm ?=                768px
$screen-sm-min ?=            $screen-sm
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet ?=            $screen-sm-min

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md ?=                992px
$screen-md-min ?=            $screen-md
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop ?=           $screen-md-min

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg ?=                1200px
$screen-lg-min ?=            $screen-lg
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop ?=        $screen-lg-min

// So media queries don't overlap when required, provide a maximum
$screen-xs-max ?=            ($screen-sm-min - 1)
$screen-sm-max ?=            ($screen-md-min - 1)
$screen-md-max ?=            ($screen-lg-min - 1)



// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container
  container-fixed()

  @media (min-width $screen-sm-min)
    width $container-sm
  @media (min-width $screen-md-min)
    width $container-md
  @media (min-width $screen-lg-min)
    width $container-lg


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid
  container-fixed()


// Row
//
// Rows contain and clear the floats of your columns.

.row
  make-row()


// Columns
//
// Common styles for small and large grid columns

make-grid-columns()


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

make-grid(xs)


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width $screen-sm-min)
  make-grid(sm)


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width $screen-md-min)
  make-grid(md)


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width $screen-lg-min)
  make-grid(lg)


.container
  max-width: 1016px;

.container.spacing-content
  padding-top:  80px  ;
  padding-bottom: 80px;

.narrow-content
  max-width: 680px;
  margin: auto;
  text-align: center;
  font-size: 120%;
  p
    color: #666;
  ul
    padding-left: 0;
    &.ul-inline
      margin: 20px 0;
      li
        background: url('/homepage/img/icon-checkmark.png') left 4px no-repeat;
        display: inline-block
        padding-left: 30px;
        margin: 0 20px;

    &.ul-large
      text-align: left;

    li
      margin-bottom: 20px;
      background: url('/homepage/img/icon-checkmark.png') left 4px no-repeat;
      padding-left: 30px;
      list-style: none;
