// Mixins
// --------------------------------------------------
vendors ?= official

percentage($n)
  if $n == 0
    0
  else
    unit($n * 100, "%")

// Utilities

@import "mixins/hide-text"
@import "mixins/opacity"
@import "mixins/image"
@import "mixins/labels"
@import "mixins/reset-filter"
@import "mixins/resize"
@import "mixins/responsive-visibility"
@import "mixins/size"
@import "mixins/tab-focus"
@import "mixins/reset-text"
@import "mixins/text-emphasis"
@import "mixins/text-overflow"
@import "mixins/vendor-prefixes"

// Components
@import "mixins/alerts"
@import "mixins/buttons"
@import "mixins/panels"
@import "mixins/pagination"
@import "mixins/list-group"
@import "mixins/nav-divider"
@import "mixins/forms"
@import "mixins/progress-bar"
@import "mixins/table-row"

// Skins
@import "mixins/background-variant"
@import "mixins/border-radius"
@import "mixins/gradients"

// Layout
@import "mixins/clearfix"
@import "mixins/center-block"
@import "mixins/nav-vertical-align"
@import "mixins/grid-framework"
@import "mixins/grid"



@import '../vars/index.styl';

clearfix()
  &:after
    clear: both

  &:after, &:before
    content: ""
    display: table

  *zoom: 1
  
box-shadow()
  box-shadow: arguments
  -webkit-box-shadow: arguments
  -moz-box-shadow: arguments

breakpoint(point, orientation = "min")
  if point == "mobile"
    if orientation == "max"
      @media ({orientation}-width: (breakpoint-mobile - 1) )
        {block}
    else
      @media ({orientation}-width: breakpoint-mobile)
        {block}

  else if point == "mobile-landscape"
    if orientation == "max"
      @media ({orientation}-width: (breakpoint-mobile-landscape - 1) )
        {block}
    else
      @media ({orientation}-width: breakpoint-mobile-landscape)
        {block}


  else if point == "tablet"
    if orientation == "max"
      @media ({orientation}-width: (breakpoint-tablet - 1) )
        {block}
    else
      @media ({orientation}-width: breakpoint-tablet)
        {block}

  else if point == "desktop"
    if orientation == "max"
      @media ({orientation}-width: (breakpoint-desktop - 1) )
        {block}
    else
      @media ({orientation}-width: breakpoint-desktop)
        {block}

  else if point == "desktop-hd"
    if orientation == "max"
      @media ({orientation}-width: (breakpoint-desktop-hd - 1) )
        {block}
    else
      @media ({orientation}-width: breakpoint-desktop-hd)
        {block}

  else if point == "retina-only"
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)
      {block}

grid(cols = 1, gutter = 0, childs = ".item", orientation= "right")
  clearfix()
  width: auto
  margin-right: -(gutter)

  {childs}
    float: left
    width: (100% / cols)
    padding-{orientation}: gutter
    background-clip: content-box
