// Avenir

// Regular

@font-face {
  font-family: "avenir-next-web";
  src: url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular.eot?#iefix") format("eot")
}

@font-face {
  font-family: "avenir-next-web";
  src: url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular.eot?#iefix");
  src: url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular.eot?#iefix") format("eot"),
       url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular.woff2") format("woff2"),
       url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular.woff") format("woff"),
       url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular.ttf") format("truetype"),
       url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular.svg#avenir-next-regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

// Regular italic

@font-face {
  font-family:"avenir-next-web";
  src:url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular-italic.eot?#iefix") format("eot")
}

@font-face {
  font-family:"avenir-next-web";
  src:url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular-italic.eot?#iefix");
  src:url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular-italic.eot?#iefix") format("eot"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular-italic.woff2") format("woff2"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular-italic.woff") format("woff"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular-italic.ttf") format("truetype"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-regular-italic.svg#avenir-next-regular-italic") format("svg");
  font-weight: normal;
  font-style: italic;
}


// Medium

@font-face {
  font-family: "avenir-next-web";
  src: url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium.eot?#iefix") format("eot")
}
@font-face {
  font-family:"avenir-next-web";
  src:url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium.eot?#iefix");
  src:url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium.eot?#iefix") format("eot"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium.woff2") format("woff2"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium.woff") format("woff"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium.ttf") format("truetype"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium.svg#avenir-next-medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

// Medium italic

@font-face {
  font-family:"avenir-next-web";
  src:url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium-italic.eot?#iefix") format("eot")
}

@font-face {
  font-family:"avenir-next-web";
  src:url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium-italic.eot?#iefix");
  src:url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium-italic.eot?#iefix") format("eot"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium-italic.woff2") format("woff2"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium-italic.woff") format("woff"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium-italic.ttf") format("truetype"),
      url("//cdn.auth0.com/styleguide/latest/lib/font/avenir-next/avenir-next-medium-italic.svg#avenir-next-medium-italic") format("svg");
  font-weight: 500;
  font-style: italic;
}
