.code-picker
  .languages-bar
    position relative

    // Gradient at the end of the language bar (only in mobile)
    &:after
      +breakpoint("mobile-landscape", "max")
        content ''
        position absolute
        top 0
        right 0
        height 100%
        width 75px
        background linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.2) 200%)
        pointer-events: none

    > ul
      width 100%
      list-style-type none
      white-space nowrap
      background-color $bg-color-gray-light
      border-radius 3px 3px 0 0
      margin 0
      padding 15px 3px 15px 25px

      .theme-dark &
        background-color #3A424A

      > li
        display inline-block
        padding 0 20px
        font-size $font-size-small

        &:first-child
          padding-left 0

        &.active > a
          color $gray-darker

          .theme-dark &
            color white

      .more-dots
        font-size: 45px;
        line-height: 0;
        letter-spacing: -0.2rem;
        top: -3px;
        position: relative;

        +breakpoint("mobile-landscape", "max")
          display none

      // Only active scroll functionality in mobile view
      +breakpoint("mobile-landscape", "max")
        overflow-x auto
        position relative

    // Remove copy button in mobile view
    .btn-copy
      +breakpoint("mobile-landscape", "max")
        display none

    .dropdown
      // Remove all dropdown styles in mobile view
      +breakpoint("mobile-landscape", "max")
        display inline
        padding-left 0
        padding-right 0

        .dropdown-menu
          display inline
          position static
          margin 0
          padding 0
          float none
          border none
          box-shadow none
          background-color transparent

          > li
            display inline-block
            padding 0 20px

          > li.active a
            color $gray-darker
            background-color transparent

            .theme-dark &
              color white

            &:hover,
            &:focus,
            &:active
              color $gray-darker

              .theme-dark &
                color white

          // Make the list inline
          > li > a
            padding 0
            color $link-color

            .theme-dark &
              color lighten($link-color, 30%)

            &:hover,
            &:focus,
            &:active
              background-color transparent
              color $link-color

      +breakpoint("mobile-landscape")
        .dropdown-menu
          margin-top 20px

          > li.active a
            background-color transparent
            color #333

            &:hover
              background-color #f5f5f5

            &:after
              content ''
              display inline-block
              width 7px
              height 7px
              border-radius 50%
              background-color $color-blue-medium
              margin-left 8px

              .theme-dark &
                background-color: $color-blue-light

          // Add dropdown top caret
          &:after
            content ''
            display inline-block
            position absolute
            top -20px
            left 25px
            width 0
            height 0
            border-bottom 10px solid white
            border-left 10px solid transparent
            border-top 10px solid transparent
            border-right 10px solid transparent

          // Add caret border
          &:before
            content ''
            display inline-block
            position absolute
            top -21px
            left 25px
            width 0
            height 0
            border-bottom 10px solid rgba(0,0,0,0.15)
            border-left 10px solid transparent
            border-top 10px solid transparent
            border-right 10px solid transparent

  .tab-content
    pre code
      width 100%
      border-radius: 0 0 3px 3px
