//
// Modals
// --------------------------------------------------

//** Padding applied to the modal body
$modal-inner-padding ?=       15px

//** Padding applied to the modal title
$modal-title-padding ?=       15px
//** Modal title line-height
$modal-title-line-height ?=   $line-height-base

//** Background color of modal content area
$modal-content-bg ?=                           #fff
//** Modal content border color
$modal-content-border-color ?=                 rgba(0, 0, 0, .2)
//** Modal content border color **for IE8**
$modal-content-fallback-border-color ?=        #999

//** Modal backdrop background color
$modal-backdrop-bg ?=         #000
//** Modal backdrop opacity
$modal-backdrop-opacity ?=    .5
//** Modal header border color
$modal-header-border-color ?= #e5e5e5
//** Modal footer border color
$modal-footer-border-color ?= $modal-header-border-color

$modal-lg ?=                  900px
$modal-md ?=                  600px
$modal-sm ?=                  300px
// .modal-open - body class for killing the scroll
// .modal - container to scroll within
// .modal-dialog - positioning shell for the actual modal
// .modal-content - actual modal w/ bg and corners and shit

.modal-open
  overflow hidden

// Container that the modal scrolls within
.modal
  display none
  overflow hidden
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  z-index $zindex-modal
  -webkit-overflow-scrolling touch

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline 0

  // When fading in the modal, animate it to slide down

  &.fade .modal-dialog
    transform translate(0, -25%)
    transition transform 0.3s ease-out

  &.in .modal-dialog
    transform translate(0, 0)

.modal-open .modal
  overflow-x hidden
  overflow-y auto

// Shell div to position the modal with bottom padding
.modal-dialog
  position relative
  width auto
  margin 10px

// Actual modal
.modal-content
  position relative
  background-color $modal-content-bg
  border 1px solid $modal-content-fallback-border-color //old browsers fallback (ie8 etc)
  border 1px solid $modal-content-border-color
  border-radius $border-radius-large
  box-shadow 0 3px 9px rgba(0, 0, 0, .5)
  background-clip padding-box
  // Remove focus outline from opened modal
  outline 0

// Modal background
.modal-backdrop
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  z-index $zindex-modal-background
  background-color $modal-backdrop-bg

  // Fade for backdrop
  &.fade
    opacity-ie(0)

  &.in
    opacity-ie($modal-backdrop-opacity)

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header
  padding $modal-title-padding
  border-bottom 1px solid $modal-header-border-color
  clearfix()

// Close icon
.modal-header .close
  margin-top -2px

// Title text within header
.modal-title
  margin 0
  line-height $modal-title-line-height

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body
  position relative
  padding $modal-inner-padding

// Footer (for actions)
.modal-footer
  padding $modal-inner-padding
  text-align right // right align buttons
  border-top 1px solid $modal-footer-border-color
  clearfix() // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn + .btn
    margin-left 5px
    margin-bottom 0 // account for input[type="submit"] which gets the bottom margin like all other inputs

  // but override that for button groups
  .btn-group .btn + .btn
    margin-left -1px

  // and override it for block buttons as well
  .btn-block + .btn-block
    margin-left 0

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure
  position absolute
  top -9999px
  width 50px
  height 50px
  overflow scroll

// Scale up the modal
@media (min-width $screen-sm-min)
  // Automatically set modal's width for larger viewports
  .modal-dialog
    width $modal-md
    margin 30px auto
  .modal-content
    box-shadow 0 5px 15px rgba(0, 0, 0, .5)

  // Modal sizes
  .modal-sm
    width $modal-sm

@media (min-width $screen-md-min)
  .modal-lg
    width $modal-lg






.modal
  background: none;
  opacity: 0;
  transition: .5s;

  .modal-backdrop
    background: rgba(244, 241, 241, 0.95);
    opacity: 1;
    z-index: 0;

    .theme-dark &
      background: rgba(black, 0.75);

  &.in
    opacity: 1;
    pointer-events: auto;

    .modal-dialog
      opacity: 1;
      -webkit-transform: none;
      transform: none;

  .modal-dialog
    max-width: 640px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    -webkit-transform: translateY(80%) scale(.8);
    transform: translateY(80%) scale(.8);
    transition-timing-function: cubic-bezier(.3, 0, 0, 1.3);
    transition-delay: .2s;
    opacity: 0;
    transition: .4s;
    overflow: hidden;

    .alert button.close
      font-size: 20px;

    .modal-content
      overflow: hidden;
      -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      border: 0;

    .modal-header
      border-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      position: relative;

      &.has-border
        border-bottom: 1px solid #E5E5E5;

      .nav-tabs
        margin-bottom: 0;
        margin-top: 20px;


      .modal-title
        font-weight: $font-weight-bold
        text-align: center

      .close
        width: 18px;
        height: 18px;
        text-indent: -10000em;
        opacity: .8;
        background-size: 100%;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAFNUlEQVRYCa1XSU8cRxSuelXdTAaGMROwPRiMcJATQ3xISLwkccQhOUS28Sm/kkMEtqVEygHJdkgikYsFUQg2nhgYsYid8TBdS+q1qVZP09302PSltrd8/V69pShp8RsZGXG33Atlns/1SKFyXLGcpKQNxTBNjgTIOuNQF7X6RqmxVp2bm2u0ooJmJe67fe+SUmKQKNqVlcenA70NwJeWZx6tZOE7FVDv6P1u1iaHpaeLKJBSqgnXNe1BHbgQShNBFZF4poEwoIQrwTl1VM6c5LXWvg7m0F15xOZXZx9uIm3SlwgIBfXfenBdKW/gLbN6A5zsGQvVk4TF7oPOKUE6CYEP8BzAqbz+ffK5/2MxDLGARkdHnapz/ob57xIDcuQpvc3NGMOfeUso0uYA7ZJmJIxslb31P2dnZ72oABbdQDCb7PwdpUgRGSnRW8YNvkuitK2sj2UcaPAdXDqC9vLQxeJytVpVYTkQXqCb0DKC0DwwuQ5a74fPz2KOMlE26kBd9o5Z2U2A8M5QE0WKiqq5oW8s0ZmPRjbqQF2oMyw/cBlGkwmYTw36DSDwXvclrCBpDsTAAdlQSl8qlK9t7VcXakgbWAhDG+9MmmVcB0yE8cyWczgGQgo9esHo9HUfI/cthEmPKtWLFzjpj5irN149/bmyt7Kw9WHfMDGppyOJFvcpdzcrzx6/QvqegetaahFLb8K8YTLbuc7Ln9T3lhf2fQthBsbQTlKAlqk8+aVqz5f+mFpziLtm19ERwbx+NrVq91/89tM6ZfTQrqMj6vargDkArE1E6VxqngHQUSFJoKJgLB+NkWHPfN0GA2JhvO/LPuMOh2gqLEF0bAjSXrhwtXhQ/bcp7e+s/HMYdl8SmP6vfvhIK9mdpoMyImu6i4Lj0u4s5YAyNVT+/O61KFhrqTQw0iPD5LimRfmDtSlJiIWWb94dNeBOpPCA8HhiAJ2THitqCYvVvx7/HT2PW6NlEAwwk2C1ieBTPkmYAy49eT/i+AyQHebI3SRLRXlaBYP8iAUaSga5KCo0us4K6l3AoC7Ewk1LIVopncegiOYujwK2a1EHl7kyk5ssD46IBbiirZcJmltcnpl8HhYWnuMdc7Sb6Z6F+RALSCpOvdBhpqRoCtPg3EZfdD9tjVhMLdWZe50kMHhn0lJCGojwGWIBTzYy9TxpYDC0k6KvFUshFrhaIDthlHFzrNrh2mRpwtGUlhIQFAHttxeWN25ELDA9PS3SCh8yeoI3tZm4FwaDSe+0lADspAyUYz/EgFj8HOTkO5pqlCWyoySNzt4vxj+26ygYu58EauDb+4ONuui2dHGjxRC8Ovpujw8R1cjHEeOLwXR4F43Zt83DTONrJK0c2DLD2/S2ENLQc0NP14lW8c0duLXlmalF1B1k6VKhGPQ7UVDYHphHIb7Juk4Dg7zWUuII6XmJgjpIBGPow7oDC6Gg/q/He7VoJJvWPPr8qp3xAWCMk+OcmUY0wTJGZzR6mwDhk2Tgzr1B5aW3pwj+LD5w2EHlyaOl8Cs2cBkqwIOb5XzlbXN+FiqTZaAO1BUGg9TBM8iyzs/P6+9vfbazWpN5rbRr989yRMvc6O1YmpiYOFElmlwWVoruu/zNg3LqnQozZJzjnfnv6WQ1ahnL3uQyu4kjMmB2LhV7XhATluGzd5obGSgLZSaB8fVmFX7lux+LXu2g2zyw2rPy+ApMBsak9/LXid0sfIkuS2IeGxvjL0V7gRMoeEo4THNHUuU3a0yDwBbCAe4Jovav8MN9LAdJsuL2/we/urKeUsCFcAAAAABJRU5ErkJggg==');
        margin-top: 0;
        position: absolute
        margin-right: 15px;
        right: 0;
        top: 15px;

        &:hover
          opacity: 1;
        &:focus
          outline: none;

    .modal-body
      overflow-y: auto;
      border: 0;
      max-height: none;
      min-height: 70px;
      padding: 20px 35px;

    .modal-title
      line-height: 1;
      font-size: $font-size-base;

    .modal-footer
      text-align: center

      .btn
        font-size: $font-size-small;

    button.close
      background: none;
      font-size: 34px;
      font-weight: normal

  .form-horizontal .control-label
    +breakpoint("tablet")
      padding-top: 7px;
      margin-bottom: 0;
      text-align: right;

.modal-separator
  border-top: 1px solid #E5E5E5;
  margin: 0 -35px;
