.showcase-list
  list-style: none
  margin 0
  padding 0

  > li
    display inline-block
    margin-top 10px

    &:not(:last-child)
      margin-right 30px

      +breakpoint("tablet")
        margin-right 40px

    &.active a:after
      display block
      content ''
      width 0
      height 0
      border-left 5px solid transparent
      border-right 5px solid transparent
      border-top 5px solid #000
      margin 2px auto 0 auto

    a
      color rgba(0, 0, 0, .86)
      font-size 16px
      cursor pointer
      transition all .3s ease

      +breakpoint("tablet")
        font-size 20px

    .theme-dark &
      a
        color rgba(255, 255, 255, .86)

      &.active a:after
        border-top-color rgba(255, 255, 255, .86)
